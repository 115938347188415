import './App.css';
import React, { useState, useEffect } from 'react';
import Popup from './components/pop-up';
import loadingGif from './static/img/loading.gif'; // 加载中的 GIF 图像路径

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
    // 关闭网页的逻辑
    window.close();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true); // 2秒后显示弹窗
      setShowLoading(false); // 隐藏加载中的 GIF 图像
    }, 2000); // 2000毫秒 = 2秒

    // 在组件卸载时清除定时器，避免内存泄漏
    return () => {
      clearTimeout(timer);
    };
  }, []); // 仅在组件挂载时执行一次

  return (
    <div className='App' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {showLoading && <img src={loadingGif} alt="Loading..." />} {/* 显示加载中的 GIF 图像 */}
      {showPopup && <Popup onClose={handleClosePopup} />} {/* 显示弹窗 */}
    </div>
  );
}

export default App;
