import React from 'react';

const Popup = ({ onClose }) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50'>
      <div className='bg-white p-8 rounded-lg'>
        <p className='text-lg mb-4'>您不在门店服务范围内，请选择您所在门店。</p>
        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={onClose}>确定</button>
      </div>
    </div>
  );
};

export default Popup;
